import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout/Layout";
import PageHero from "../components/PageHero/PageHero";
import MasonryGrid from "../components/Masonry/MasonryGrid";
import MasonryCard from "../components/Masonry/MasonryCard";
import Seo from "../components/seo";

const events = ({ data: { page, allWpEvent } }) => {
  const { hero, subsection } = page.header;
  const events = allWpEvent.edges;
  return (
    <Layout>
      <Seo
        title={hero.title}
        description={subsection.text}
        image={hero?.image?.localFile?.childImageSharp.original?.src}
      />
      <PageHero
        title={hero.title}
        src={hero?.image?.localFile}
        subtitle={subsection.subtitle}
        text={subsection.text}
      />
      <section
        className="border-t border-lightest border-opacity-10"
        data-sal="fade"
        data-sal-duration="2000"
        data-sal-easing="ease"
      >
        <div className="container">
          <MasonryGrid title={""}>
            {events.map((item, index) => (
              <MasonryCard
                key={index}
                title={item.node?.title}
                text={item.node?.eventstype?.description}
                image={item.node?.eventstype?.image}
                published={item.node?.date}
              >
                <Link to={`/events/${item.node?.slug}`}>Läs mer</Link>
              </MasonryCard>
            ))}
          </MasonryGrid>
        </div>
      </section>
    </Layout>
  );
};

export default events;

export const pageQuery = graphql`
  query EventsPage {
    page: wpPage(title: { eq: "Events" }) {
      header {
        hero {
          title
          image {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        subsection {
          subtitle
          text
        }
      }
    }
    allWpEvent(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          date(formatString: "LL", locale: "sv")
          eventstype {
            description
            location
            starting
            ending
            prices {
              price
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    height: 150
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
