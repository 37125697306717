import React from "react";
import FluidImage from "../FluidImage/FluidImage";
import "./PageHero.scss";

const PageHero = ({ title, src, subtitle, text, small, breakText }) => {
  return (
    <>
      <div className={`page-hero ${small ? "small" : ""}`}>
        {src && (
          <div className="bg-img">
            <FluidImage src={src} title={title} />
          </div>
        )}
        <div className="bg-overlay"></div>
        <div className="bg-title">
          <h1 className={`pt-[80px] h2 lg:h1 ${breakText ? "break-text" : ""}`}>
            {title}
          </h1>
        </div>
      </div>
      {subtitle && (
        <section className="page-subtitle">
          <div className="container max-w-4xl mx-auto">
            <div className="text-center max-w-2xl mx-auto">
              <h2 className="h3">{subtitle}</h2>
            </div>
            <p className="text-center md:text-left">{text}</p>
          </div>
        </section>
      )}
    </>
  );
};

export default PageHero;
