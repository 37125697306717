import React from "react";
import FluidImage from "../FluidImage/FluidImage";
import "./MasonryCard.scss";

const MasonryCard = ({
  children,
  title,
  text,
  image,
  published,
  product,
  fullHeight,
  shopCard,
  expand,
}) => {
  return (
    <div
      className={`masonry-item masonry-card ${fullHeight ? "h-full" : ""} ${
        product ? "is-product" : ""
      }`}
    >
      {image?.localFile && (
        <div className={`header`}>
          <FluidImage  src={image.localFile} title={title} />
          <div className="header-darken"></div>
        </div>
      )}
      <div className="body">
        {published && (
          <time className="text-xs text-white opacity-50">
            Publicerad: {published}
          </time>
        )}
        {title && (
          <h3 className={`line-clamp-2 ${shopCard ? "text-base" : "h4"}`}>
            {title}
          </h3>
        )}
        {text && (
          <div className={`text-peak ${expand ? "expand" : ""}`}>
            <div
              className={` ${
                shopCard ? "line-clamp-none h-auto" : "line-clamp-2"
              }  max-w-lg  text-white opacity-70`}
            >
              {text.replace(/<[^>]+>/g, "")}
            </div>
          </div>
        )}
      </div>
      {children && <div className="footer mt-auto">{children}</div>}
    </div>
  );
};

export default MasonryCard;
