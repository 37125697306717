import React from "react";
import Masonry from "react-masonry-css";
import "./Masonry.scss";

const MasonryGrid = ({ children, title, customColXs, customCol }) => {
  const breakpointColumnsObj = {
    default: customCol ? customCol : 4,
    1100: 3,
    700: 2,
    500: customColXs ? customColXs : 1,
  };
  return (
    <>
      {title && (
        <div className="container text-center pt-12 lg:pt-16">
          <h2 className="h3 title">{title}</h2>
        </div>
      )}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry-grid py-8 lg:py-12"
        columnClassName="masonry-grid_column"
      >
        {children}
      </Masonry>
    </>
  );
};

export default MasonryGrid;
